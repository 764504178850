<template>
  <div class="body">
    <el-container class="container">
      <el-header height="50px">
        <img src="@/assets/mg_header.png" style="width:100%;" />
      </el-header>
      <el-container>
    <el-aside style="width:81%;min-width:730px;">
      <el-container>
    <el-main style="padding:0;height:610px;overflow-y:auto;">
      <!-- <div style="width:100%;text-align:left;margin-top:20px;">
        <img src="@/assets/mg_logo.png" style="float:left" />
        <div class="service_dialog" @click="dialogshow5 = true" data-after="小芒" v-html="service_html4" ref="service4"></div>
      </div> -->
      <div style="width:100%;text-align:right;margin-top:50px;">
        <div class="avtar_dialog" @click="dialogshow1 = true" v-html="user_html"></div>
        <img src="@/assets/mg_user.png" style="float:right" />
      </div>
      <div style="width:100%;text-align:left;margin-top:30px;">
        <img src="@/assets/mg_logo.png" style="float:left" />
        <div class="service_dialog" @click="dialogshow2 = true" data-after="小芒" v-html="service_html" ref="service1"></div>
      </div>

      <!-- <div style="width:100%;text-align:right;margin-top:10px;">
        <div class="avtar_dialog" @click="dialogshow6 = true" v-html="user_html2"></div>
        <img src="@/assets/mg_user.png" style="float:right" />
      </div>

      <div style="width:100%;text-align:left;margin-top:10px;">
        <img src="@/assets/mg_logo.png" style="float:left" />
        <div class="service_dialog" @click="dialogshow3 = true" data-after="小芒" v-html="service_html2" ref="service2"></div>
      </div>

      <div style="width:100%;text-align:right;margin-top:10px;">
        <div class="avtar_dialog" @click="dialogshow7 = true" v-html="user_html3"></div>
        <img src="@/assets/mg_user.png" style="float:right" />
      </div>

      <div style="width:100%;text-align:left;margin-top:10px;">
        <img src="@/assets/mg_logo.png" style="float:left" />
        <div class="service_dialog" @click="dialogshow4 = true" data-after="小芒" v-html="service_html3" ref="service3"></div>
      </div> -->
    </el-main>
    <el-footer style="padding:0;" height="169px">
      <img src="@/assets/mg_footer.png" style="margin-top:-2px;width:100%" />
      </el-footer>
  </el-container>
    </el-aside >
    <el-main style="padding:3px 0 0 0;"><img src="@/assets/mg_aside.png" style="margin-top:-2px;" /></el-main>
  </el-container>
    </el-container>
  <el-dialog
  top="16vh"
  :visible.sync="dialogshow1"
  width="550px"
  title="用户对话内容"
  center
  :before-close="handleClose">
  <el-input
  type="textarea"
  :rows="6"
  placeholder="请输入用户对话内容"
  v-model="user_text"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="user_confirm()" type="primary">确定</el-button>
  </span>
</el-dialog>
<el-dialog
  top="16vh"
  :visible.sync="dialogshow2"
  width="550px"
  title="芒果tv客服对话内容"
  center
  :before-close="handleClose">
  <el-input 
  type="textarea"
  :rows="6"
  placeholder="请输入芒果tv客服对话内容"
  v-model="service_text"></el-input>
  <!-- <p style="margin-top:20px"><label>客服名称：</label><el-input v-model="kfname"></el-input></p> -->
  <span slot="footer" class="dialog-footer">
    <el-button @click="service_confirm()" type="primary">确定</el-button>
  </span>
</el-dialog>
<el-dialog
  top="16vh"
  :visible.sync="dialogshow3"
  width="550px"
  title="芒果tv客服对话内容"
  center
  :before-close="handleClose">
  <el-input 
  type="textarea"
  :rows="6"
  placeholder="请输入芒果tv客服对话内容"
  v-model="service_text2"></el-input>
  <p style="margin-top:20px"><label>客服名称：</label><el-input v-model="kfname"></el-input></p>
  <span slot="footer" class="dialog-footer">
    <el-button @click="service_confirm2()" type="primary">确定</el-button>
  </span>
</el-dialog>
<el-dialog
  top="16vh"
  :visible.sync="dialogshow4"
  width="550px"
  title="芒果tv客服对话内容"
  center
  :before-close="handleClose">
  <el-input 
  type="textarea"
  :rows="6"
  placeholder="请输入芒果tv客服对话内容"
  v-model="service_text3"></el-input>
  <p style="margin-top:20px"><label>客服名称：</label><el-input v-model="kfname"></el-input></p>
  <span slot="footer" class="dialog-footer">
    <el-button @click="service_confirm3()" type="primary">确定</el-button>
  </span>
</el-dialog>
<el-dialog
  top="16vh"
  :visible.sync="dialogshow5"
  width="550px"
  title="芒果tv客服对话内容"
  center
  :before-close="handleClose">
  <el-input 
  type="textarea"
  :rows="6"
  placeholder="请输入芒果tv客服对话内容"
  v-model="service_text4"></el-input>
  <p style="margin-top:20px"><label>客服名称：</label><el-input v-model="kfname"></el-input></p>
  <span slot="footer" class="dialog-footer">
    <el-button @click="service_confirm4()" type="primary">确定</el-button>
  </span>
</el-dialog>
<el-dialog
  top="16vh"
  :visible.sync="dialogshow6"
  width="550px"
  title="用户对话内容"
  center
  :before-close="handleClose">
  <el-input
  type="textarea"
  :rows="6"
  placeholder="请输入用户对话内容"
  v-model="user_text2"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="user_confirm2()" type="primary">确定</el-button>
  </span>
</el-dialog>
<el-dialog
  top="16vh"
  :visible.sync="dialogshow7"
  width="550px"
  title="用户对话内容"
  center
  :before-close="handleClose">
  <el-input
  type="textarea"
  :rows="6"
  placeholder="请输入用户对话内容"
  v-model="user_text3"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="user_confirm3()" type="primary">确定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
export default {
  name: "iqiyi",
  data() {
    return {
      user_text: "",
      user_text2: "",
      user_text3: "",
      service_text:"",
      service_text2:"",
      service_text3:"",
      service_text4:"",
      user_html: "",
      user_html2: "",
      user_html3: "",
      service_html:"",
      service_html2:"",
      service_html3:"",
      service_html4:"",
      dialogshow1:false,
      dialogshow2:false,
      dialogshow3:false,
      dialogshow4:false,
      dialogshow5:false,
      dialogshow6:false,
      dialogshow7:false,
      kfname:'小芒',
    };
  },
  mounted() {
    
  },
  methods: {
  handleClose(done) {
            done();
      },
  user_confirm(){
    this.user_html=""
    var user =this.user_text.split(/[(\r\n)\r\n]+/);
    for(let i in user){
      this.user_html+="<p>"+user[i]+"</p>"
    }
    this.dialogshow1 = false ;
  },
  user_confirm2(){
    this.user_html2=""
    var user =this.user_text2.split(/[(\r\n)\r\n]+/);
    for(let i in user){
      this.user_html2+="<p>"+user[i]+"</p>"
    }
    this.dialogshow6 = false ;
  },
  user_confirm3(){
    this.user_html3=""
    var user =this.user_text3.split(/[(\r\n)\r\n]+/);
    for(let i in user){
      this.user_html3+="<p>"+user[i]+"</p>"
    }
    this.dialogshow7 = false ;
  },
  service_confirm(){
    this.service_html=""
    var service =this.service_text.split(/[(\r\n)\r\n]+/);
    for(let i in service){
      this.service_html+="<p>"+service[i]+"</p>"
    }
    this.$refs.service1.setAttribute("data-after",this.kfname)
    this.$refs.service2.setAttribute("data-after",this.kfname)
    this.$refs.service3.setAttribute("data-after",this.kfname)
    this.$refs.service4.setAttribute("data-after",this.kfname)
    this.dialogshow2 = false ;
  },
  service_confirm2(){
    this.service_html2=""
    var service =this.service_text2.split(/[(\r\n)\r\n]+/);
    for(let i in service){
      this.service_html2+="<p>"+service[i]+"</p>"
    }
    this.$refs.service1.setAttribute("data-after",this.kfname)
    this.$refs.service2.setAttribute("data-after",this.kfname)
    this.$refs.service3.setAttribute("data-after",this.kfname)
    this.$refs.service4.setAttribute("data-after",this.kfname)
    this.dialogshow3 = false ;
  },
  service_confirm3(){
    this.service_html3=""
    var service =this.service_text3.split(/[(\r\n)\r\n]+/);
    for(let i in service){
      this.service_html3+="<p>"+service[i]+"</p>"
    }
    this.$refs.service1.setAttribute("data-after",this.kfname)
    this.$refs.service2.setAttribute("data-after",this.kfname)
    this.$refs.service3.setAttribute("data-after",this.kfname)
    this.$refs.service4.setAttribute("data-after",this.kfname)
    this.dialogshow4 = false ;
  },
  service_confirm4(){
    this.service_html4=""
    var service =this.service_text4.split(/[(\r\n)\r\n]+/);
    for(let i in service){
      this.service_html4+="<p>"+service[i]+"</p>"
    }
    this.$refs.service1.setAttribute("data-after",this.kfname)
    this.$refs.service2.setAttribute("data-after",this.kfname)
    this.$refs.service3.setAttribute("data-after",this.kfname)
    this.$refs.service4.setAttribute("data-after",this.kfname)
    this.dialogshow5 = false ;
  },
  clearborder(){
    this.dialogshow2 = true
  }
  },
};
</script>
<style lang="less" scoped>
body {
  width: 100%;
  background-color: #fff;
}
.set {
  color: #3743d6;
  cursor: pointer;
  width: 100px;
}
.active {
  background: #3743d6;
  color: #fff;
}
.el-header {
  padding: 0;
}
.container {
  height: 840px;
  width: 73%;
  min-width: 1000px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 11px 6px 24px rgba(0, 0, 0 , 0.4);
  overflow: hidden;
}
.el-main {
  position: relative;
  height: 100%;
  z-index: 100;
  padding-left: 0px;
  padding-right: 0;
  border-left: 1px solid #fff;
  overflow: initial;
}
.el-input {
  width: 300px;
  margin-left: 20px;
}
.body {
  background-color: #495A6D;
  width: 100%;
  height: 930px;
  position: absolute;
}
.avtar_dialog{min-width: 100px;max-width: 600px; min-height:40px;background-color: #F4F4F4;display: inline-block;margin-top: 10px;
border-radius: 6px;color:#454545;text-align: left;line-height: 25px;font-size: 12px;padding: 10px 14px;border: 1px solid #DADADA;
font-family: "Microsoft YaHei";cursor: pointer;position: relative;margin-right: 15px;
}
.service_dialog{min-width: 100px;max-width: 600px; min-height:40px;background-color: #EAFCFF;display: inline-block;margin-top: 10px;margin-left: 15px;
border-radius: 6px 6px 6px 6px;color:#454545;text-align: left;line-height: 25px;font-size: 12px;padding: 10px 14px;border: 1px solid #C4EAF1;
font-family:"Microsoft YaHei";cursor: pointer;position: relative;margin-top: 25px;}
.vip{position: absolute;width: 11px;height: 11px;display: block;}
.avtar_dialog:before{
    content: "";
    display: block;
    width: 16px;
    height: 22px;
    position: absolute;
    top: 0;
    right: -10px;
    background-image: url("../../assets/mg_qpr_bg.png");
    background-size:cover;
}
.service_dialog:before{
    content: "";
    display: block;
    width: 16px;
    height: 22px;
    position: absolute;
    top: 0;
    left: -10px;
    background-image: url("../../assets/mg_qp_bg.png");
    background-size:cover;
}
.service_dialog:after{
    content: attr(data-after);
    display: block;
    width: 70px;
    height: 24px;
    position: absolute;
    top: -25px;
    color: #888;
}
</style>